import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Input from 'rsuite/Input';
import Modal from 'rsuite/Modal';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { TextField } from '../../../containers/styled/inputs';
import { WarningSpan } from '../../../containers/styled/layout';
import { SelectPickerField, TextAreaField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import {
  createFacilityType,
  getFacilityTypes,
  updateFacilityType
} from '../../../controllers/facilities/FacilityTypeController';
import { listUserRole } from '../../../controllers/users/UserRoleController';
import {
  validateLength,
  validateNameUniqueness
} from '../../../controllers/validators';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  placeholder: {
    id: 'facility.type.form.placeholder',
    defaultMessage: 'Enter Name of Facility Type'
  },

  rolePlaceholder: {
    id: 'users.role.form.placeholder',
    defaultMessage: 'Select Role'
  },

  detailsPlaceholder: {
    id: 'facility.type.details.form.placeholder',
    defaultMessage: 'Enter Details'
  },

  // CREATE
  createTitle: {
    id: 'facility.type.create.title',
    defaultMessage: 'Create Facility Type'
  },

  createHeader: {
    id: 'facility.type.form.create.header',
    defaultMessage: 'Created'
  },

  createSuccess: {
    id: 'facility.type.form.create.success',
    defaultMessage: 'Facility Type Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'facility.type.form.update.title',
    defaultMessage: 'Edit Facility Type'
  },

  updateHeader: {
    id: 'facility.type.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'facility.type.form.update.success',
    defaultMessage: 'Facility Type Updated Successfully'
  },

  failed: {
    id: 'facility.type.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'facility.type.form.save.failed',
    defaultMessage: 'Could not save Facility Type'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'facility.type.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'facility.type.form.name.error.exists',
    defaultMessage: 'Facility Type already exists'
  },
  nameMaxLengthError: {
    id: 'facility.type.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

const MAX_LENGTH = 40;

/**
 * Facility Type Form screen
 *
 * Facility category form to create / update categories.
 *
 * @component
 * @param {object} item                prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose        handles modal close event
 * @returns type form modal.
 *
 */
const FacilityTypeForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [list, setList] = React.useState([]);
  const [data, setData] = React.useState({ name: '', details: '', role: null });
  const [nameErrors, setNameErrors] = React.useState([]);
  const [roleList, setRoleList] = React.useState([]);

  const validateName = () => {
    setNameErrors([]);
    const errors = [];

    try {
      validateLength(data.name, MAX_LENGTH);
    } catch (err) {
      errors.push(
        err.message === 'blank'
          ? formatMessage(messages.requiredError)
          : formatMessage(messages.nameMaxLengthError, { limit: MAX_LENGTH })
      );
    }

    try {
      validateNameUniqueness(data.name, data.id, list);
    } catch (err) {
      errors.push(formatMessage(messages.nameExistsError));
    }

    setNameErrors([errors]);
    return errors.length === 0;
  };

  const submit = (event) => {
    event.stopPropagation();

    if (!validateName()) return;

    let action;
    let header;
    let message;

    if (!data.id) {
      action = createFacilityType;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateFacilityType;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  React.useEffect(() => {
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      details: entry.details || '',
      role: entry.role
    });
  }, [item]);

  React.useEffect(() => {
    // getting facility type list
    getFacilityTypes(db).then(setList);

    // getting role list
    listUserRole(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({ value: entry.id, label: entry.name }));
      setRoleList(docs);
    });
  }, [db]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(
        !data.id ? messages.createTitle : messages.updateTitle
      )}
    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='facility.type.form.label.name'
                  defaultMessage='Name'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                name='name'
                value={data.name}
                onChange={(value) => {
                  setNameErrors([]);
                  setData({ ...data, name: value });
                }}
                placeholder={formatMessage(messages.placeholder)}
              />

              {nameErrors.map((msg) => (
                <WarningSpan key={msg}>{msg}</WarningSpan>
              ))}
            </Col>
            <Col md={12}>
              <Label>
                <FormattedMessage
                  id='user.role.form.label.name'
                  defaultMessage='Role'
                />
              </Label>

              <SelectDiv>
                <SelectPickerField
                  name='role'
                  data={roleList}
                  placeholder={formatMessage(messages.rolePlaceholder)}
                  value={data.role ? data.role.id : ''}
                  onClean={() => {
                    setData({ ...data, role: null });
                  }}
                  onSelect={(value, option) => {
                    setData({
                      ...data,
                      role: { id: value, name: option.label }
                    });
                  }}
                />
              </SelectDiv>
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='facility.type.form.label.details'
                  defaultMessage='Details'
                />
              </Label>
              <TextAreaField>
                <Input
                  data-testid='details'
                  name='details'
                  as='textarea'
                  rows={3}
                  value={data.details}
                  onChange={(value) => setData({ ...data, details: value })}
                  placeholder={formatMessage(messages.detailsPlaceholder)}
                />
              </TextAreaField>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>

      <Modal.Footer>
        <FlexboxGrid justify='end'>
          <FlexboxGrid.Item colspan={12}>
            <PrimaryButton data-testid='submit' onClick={submit} style={{ width: '100%' }}>
              <FormattedMessage
                id='facility.type.form.submit'
                defaultMessage='Submit'
              />
            </PrimaryButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </OpenModal>
  );
};

export default FacilityTypeForm;
