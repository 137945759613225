import styled from 'styled-components';

import { ViewLabel } from '../../containers/styled/typography';

export const StyledSubHeading = styled(ViewLabel)`
    color: ${(props) => props.theme.colors.font};
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
`;
