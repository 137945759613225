import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { deleteFacilityCategory } from '../../controllers/facilities/FacilityCategoryController';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/form';

const messages = defineMessages({
  title: {
    id: 'donationSchedule.title',
    defaultMessage: 'Donation Schedules'
  },
  count: {
    id: 'donationSchedule.label',
    defaultMessage: 'Schedules'
  },
  searchPlaceHolder: {
    id: 'donationSchedule.search.placeholder',
    defaultMessage: 'Search'
  },
  name: {
    id: 'donationSchedule.donationCenter',
    defaultMessage: 'Blood Donation Center'
  },
  details: {
    id: 'donationSchedule.schedules',
    defaultMessage: 'Schedules'
  },
  status: {
    id: 'donationSchedule.status',
    defaultMessage: 'Status'
  },
  add: {
    id: 'donationSchedule.add.button',
    defaultMessage: 'Create Schedule'
  }
});

const ADD = permissions.donationSchedule.add;
const EDIT = permissions.donationSchedule.edit;
const DELETE = permissions.donationSchedule.del;

/**
 * Donation Schedule screen
 *
 * List of available Donation Schedule.
 *
 * @component
 * @example
 * return (
 *    <DonationSchedule />
 * );
 */
const DonationSchedule = () => {
  const data = [
    {
      name: 'Gorrobawa Blood Bank',
      details: '5 Schedules',
      test: 'Disabled'
    },
    {
      name: 'Tarauni Primary Health Centre',
      details: '5 Schedules',
      test: 'Active'
    }
  ];

  return (
    <MarginDiv data-testid='donation-schedule'>
      <DataEntity
        crumbs={['configurationsGroup', 'donationSchedule']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'details', label: messages.details },
          { dataKey: 'test', label: messages.status }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteFacilityCategory}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default DonationSchedule;
