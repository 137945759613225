import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { getCurrentMonthData } from '../../../utils';
import { StyledSubHeading } from '../styled';

const BloodDonations = ({ title, donationsChildData, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [keys, setKeys] = useState([]);
  const [maleData, setMaleData] = useState([]);
  const [femaleData, setFemaleData] = useState([]);
  const chartRef = useRef(null);

  const headingStyle = {
    color: '#010005',
    marginTop: 0
  };

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await getCollection(db, 'donation')
          .find()
          .where({ isActive: true })
          .exec();

        const documents = getCurrentMonthData(entries).map((data) => ({
          ...data,
          monthYear: new Date(data.createdAt).toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' })
        }));

        const groupedData = groupBy(documents, 'monthYear');
        setKeys(Object.keys(groupedData));

        const formattedData = Object.keys(groupedData).map((data) => ({
          key1: groupedData[data],
          male: groupedData[data].filter((item) => item._data.donor.gender === 'M').length,
          female: groupedData[data].filter((item) => item._data.donor.gender === 'F').length
        }));

        setMaleData(formattedData.map((item) => item.male));
        setFemaleData(formattedData.map((item) => item.female));
        donationsChildData({
          key: Object.keys(groupedData),
          maleData: formattedData.map((item) => item.male),
          femaleData: formattedData.map((item) => item.female)
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [db]);

  const series = [
    {
      data: maleData,
      type: 'bar',
      stack: 'Male',
      name: 'Male',
      itemStyle: {
        borderRadius: [50, 50, 50, 50]
      },
      emphasis: {
        disabled: true
      },
      label: {
        show: true,
        position: 'insideTop',
        fontSize: 12
      }
    },
    {
      data: femaleData,
      type: 'bar',
      stack: 'Female',
      name: 'Female',
      itemStyle: {
        borderRadius: [50, 50, 50, 50]
      },
      emphasis: {
        disabled: true
      },
      label: {
        show: true,
        position: 'insideTop',
        fontSize: 12
      }
    }
  ];

  const options = {
    tooltip: {
      trigger: 'item'
    },
    grid: {
      top: 0,
      bottom: '8%'
    },
    xAxis: {
      type: 'category',
      data: keys,
      axisTick: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D9D9D9'
        }
      },
      axisLabel: {
        color: '#4A4A4A',
        fontFamily: 'Manrope'
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        fontFamily: 'Manrope'
      }
    },
    series,
    color: ['#B02C17', '#FBE7E3'],
    barWidth: 34
  };

  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        onEvents={{
          finished: handleChartFinished
        }}
        ref={chartRef}
      />
    </StyledChartsPanel>
  );
};

export default BloodDonations;
