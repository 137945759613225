import configIcon from '../../assets/svg-images/config.svg';
import dashboardIcon from '../../assets/svg-images/dashboard-icon.svg';
import facilitiesIcon from '../../assets/svg-images/facilities.svg';
import reportsIcon from '../../assets/svg-images/reports.svg';
import rolesIcon from '../../assets/svg-images/roles.svg';
import usersIcon from '../../assets/svg-images/users.svg';
import { permissions } from '../../utils/auth';

/**
 * Dictionary with the app routes
 */
export const routes = {
  /**
   * Home route
   */
  home: '/',

  /**
   * Signin route
   */
  signin: '/signin',

  /**
   * Dashboard route
   */
  dashboard: '/dashboard',

  /**
   * Facility list route
   */
  facility: '/facilities/facility',

  /**
   * Facility type list route
   */
  facilityType: '/facilities/type',

  /**
   * Facility category route
   */
  facilityCategory: '/facilities/category',

  /**
   * User route
   */
  user: '/users',

  /**
   * User role route
   */
  role: '/roles',

  /**
   * Donor route
   */
  donor: '/reports/donors',

  /**
   * Configuration route
   */
  donationSchedule: '/configuration/donationSchedule',

  /**
   * Appointment route
   */
  appointment: '/reports/appointments',

  /**
   * Blood Donations route
   */
  donation: '/reports/blood-donations',

  /**
   * Testing route
   */
  test: '/reports/testing',

  /**
   * Processing route
   */
  processing: '/reports/processing',

  /**
   * Deliveries route
   */
  delivery: '/reports/deliveries',

  /**
   * Patients route
   */
  patient: '/reports/patients',

  /**
   * Requests route
   */
  request: '/reports/requests',

  /**
   * Cross Matching route
   */
  crossMatching: '/reports/cross-matching',

  /**
   * Adverse Event route
   */
  adverseEvent: '/reports/adverse-event'
};

/**
 * List of route groups
 */
const routesTree = [
  /**
   * Dashboard route
   */
  {
    id: 'dashboard',
    icon: dashboardIcon,
    label: 'dashboard',
    route: routes.dashboard,
    permissions: []
  },

  /**
   * Facility routes
   */
  {
    id: 'facilities',
    icon: facilitiesIcon,
    label: 'facilitiesGroup',
    items: [
      {
        id: 'facility',
        label: 'facility',
        route: routes.facility,
        permissions: [permissions.facility.view]
      },
      {
        id: 'category',
        label: 'facilityCategory',
        route: routes.facilityCategory,
        permissions: [permissions.facilityCategory.view]
      },
      {
        id: 'type',
        label: 'facilityType',
        route: routes.facilityType,
        permissions: [permissions.facilityType.view]
      }
    ]
  },

  /**
   * configurations routes
   */
  {
    id: 'configuration',
    icon: configIcon,
    label: 'configurationsGroup',
    items: [
      {
        id: 'donationSchedule',
        label: 'donationSchedule',
        route: routes.donationSchedule,
        permissions: [permissions.donationSchedule.view]
      }
    ]
  },

  /**
   * Reports routes
   */
  {
    id: 'reports',
    icon: reportsIcon,
    label: 'reportsGroup',
    items: [
      {
        id: 'donors',
        label: 'donor',
        route: routes.donor,
        permissions: [permissions.donor.view]
      },
      {
        id: 'appointments',
        label: 'appointment',
        route: routes.appointment,
        permissions: [permissions.appointment.view]
      },
      {
        id: 'donation',
        label: 'donation',
        route: routes.donation,
        permissions: [permissions.donation.view]
      },
      {
        id: 'test',
        label: 'test',
        route: routes.test,
        permissions: [permissions.test.view]
      },
      {
        id: 'processing',
        label: 'processing',
        route: routes.processing,
        permissions: [permissions.processing.view]
      },
      {
        id: 'delivery',
        label: 'delivery',
        route: routes.delivery,
        permissions: [permissions.delivery.view]
      },
      {
        id: 'patient',
        label: 'patient',
        route: routes.patient,
        permissions: [permissions.patient.view]
      },
      {
        id: 'requests',
        label: 'request',
        route: routes.request,
        permissions: [permissions.request.view]
      },
      {
        id: 'cross-matching',
        label: 'crossMatching',
        route: routes.crossMatching,
        permissions: [permissions.crossMatching.view]
      },
      {
        id: 'adverse-event',
        label: 'adverseEvent',
        route: routes.adverseEvent,
        permissions: [permissions.adverseEvent.view]
      }
    ]
  },

  /**
   * User route
   */
  {
    id: 'users',
    icon: usersIcon,
    label: 'user',
    route: routes.user,
    permissions: [permissions.user.view]
  },

  /**
   * Role route
   */
  {
    id: 'roles',
    icon: rolesIcon,
    label: 'role',
    route: routes.role,
    permissions: [permissions.role.view]
  }
];

/**
 * Navigate to Home route
 */
export const goToHome = () => {
  window.location.assign(`${routes.dashboard}/#${routes.dashboard}`);
};

/**
 * Navigate to SignIn route
 */
export const goToSignIn = () => {
  if (window.location.hash.slice(1) !== routes.signin) {
    window.location.assign(`/#${routes.signin}`);
  }
};

/**
 * Builds routes tree based on user permissions
 *
 * @param {object} auth
 * @returns list of allowed routes
 */
export const getRoutesByAuth = (auth) => {
  if (!auth || !auth.user) return [];

  if (auth.user.isSuperuser) {
    return routesTree;
  }

  const allowedRoutes = [];

  routesTree.forEach((route) => {
    if (!auth.hasPermissions(route.permissions)) return;

    const { items, ...entry } = route;
    if (items && items.length > 0) {
      const children = [];
      items.forEach((item) => {
        if (auth.hasPermissions(item.permissions)) {
          children.push(item);
        }
      });

      if (children.length > 0) {
        entry.items = children;
      }
    }

    if (entry.route || (entry.items && entry.items.length > 0)) {
      allowedRoutes.push(entry);
    }
  });

  return allowedRoutes;
};
