import { DateRangePicker, Divider, Input, InputGroup, Panel, SelectPicker } from 'rsuite';
import styled from 'styled-components';

export const StyledInputGroup = styled(InputGroup)`
  height: ${(props) => props.height};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey_border};

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.grey_dim} !important;
  }

  &:focus-within {
    outline: none !important;
    border-color: ${(props) => props.theme.colors.grey_dim} !important;
  }
`;

export const DateField = styled(DateRangePicker).attrs(props => ({ 'data-testid': props.name }))`
  .rs-picker-toggle {
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.colors.grey_dim} !important;
    }

    &:focus-visible {
      outline: none !important;
      border-color: ${(props) => props.theme.colors.grey_dim} !important;
    }

    &.rs-picker-toggle-active {
      box-shadow: none !important;
      border-color: ${(props) => props.theme.colors.grey_dim} !important;
    }
  }
`;

export const StyledInput = styled(Input).attrs(props => ({ 'data-testid': props.name }))`
  border-radius: 4px;
  padding: 21px 24px;
  color: ${(props) => props.theme.colors.grey_granite};

  &:focus {
    outline: none;
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.theme.colors.grey_alert_border};
  margin: 1rem 0;
`;

export const StyledModalDivider = styled(StyledDivider)`
  margin: 1rem 0 1.87rem;
`;

export const SelectPickerField = styled(SelectPicker).attrs(props => ({ 'data-testid': props.name }))`
  .rs-btn:hover,
  .rs-btn:focus,
  .rs-picker-toggle-active {
    border-color: ${(props) => props.theme.colors.grey_dim} !important;
  }

  .rs-picker-toggle:focus-visible {
    outline: none;
  }

  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused {
    box-shadow: none;
  }
`;

export const TextAreaField = styled.div`
  .rs-input {
    border-radius: 4px;
    padding: 21px 24px;
  }

  .rs-input:hover {
    border-color: ${(props) => props.theme.colors.grey_dim} !important;
  }

  .rs-input:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.grey_dim} !important;
  }
`;

export const StyledPanel = styled(Panel)`
  border-radius: ${(props) => props.theme.radius.sizes.m};
  border: 1px solid ${(props) => props.theme.colors.grey_border};
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 12px;
`;

export const StyledChartsPanel = styled(StyledPanel)`
  text-align:center;
  .rs-panel-body {
    padding: 34px 24px;
  }
`;
