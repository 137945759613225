import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Input from 'rsuite/Input';
import Modal from 'rsuite/Modal';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { WarningSpan } from '../../../containers/styled/layout';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import {
  createUserRole,
  listUserRole,
  updateUserRole
} from '../../../controllers/users/UserRoleController';
import { validateLength, validateNameUniqueness } from '../../../controllers/validators';

const messages = defineMessages({
  placeholder: {
    id: 'user.role.form.placeholder',
    defaultMessage: 'Enter name of role '
  },

  detailsPlaceholder: {
    id: 'user.role.form.details.placeholder',
    defaultMessage: 'Enter details '
  },

  // CREATE
  createTitle: {
    id: 'user.role.form.create.title',
    defaultMessage: 'Create role'
  },
  createHeader: {
    id: 'user.role.form.create.header',
    defaultMessage: 'Created'
  },
  createSuccess: {
    id: 'user.role.form.create.success',
    defaultMessage: 'User role created successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'user.role.form.update.title',
    defaultMessage: 'Edit role'
  },
  updateHeader: {
    id: 'user.role.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'user.role.form.update.success',
    defaultMessage: 'User role updated successfully'
  },
  failed: {
    id: 'user.role.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'user.role.form.save.failed',
    defaultMessage: 'Could not save user role'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'user.role.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'user.role.form.name.error.exists',
    defaultMessage: 'User role already exists'
  },
  nameMaxLengthError: {
    id: 'user.role.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

const MAX_LENGTH = 40;

/**
 * User Role Form screen
 *
 * User role form to create new role.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @example
 * return (
 *    <UserRoleForm />
 * );
 *
 */
const UserRoleForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [list, setList] = React.useState([]);
  const [data, setData] = React.useState({ name: '', details: '' });
  const [nameErrors, setNameErrors] = React.useState([]);

  const validateName = () => {
    setNameErrors([]);
    const errors = [];

    try {
      validateLength(data.name, MAX_LENGTH);
    } catch (err) {
      errors.push(
        err.message === 'blank'
          ? formatMessage(messages.requiredError)
          : formatMessage(messages.nameMaxLengthError, { limit: MAX_LENGTH })
      );
    }

    try {
      validateNameUniqueness(data.name, data.id, list);
    } catch (err) {
      errors.push(formatMessage(messages.nameExistsError));
    }

    setNameErrors([errors]);
    return errors.length === 0;
  };

  const submit = (event) => {
    event.stopPropagation();

    if (!validateName()) return;

    let action;
    let header;
    let message;

    if (!data.id) {
      action = createUserRole;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateUserRole;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data)
      .then((done) => {
        notify(
          done ? message : formatMessage(messages.saveFailed),
          header,
          done ? 'success' : 'error'
        );
        handleClose();
      });
  };

  React.useEffect(() => {
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      details: entry.details || ''
    });
  }, [item]);

  React.useEffect(() => {
    listUserRole(db).then(setList);
  }, [db]);

  return (
    <OpenModal
      onClose={handleClose}
      title={formatMessage(!data.id ? messages.createTitle : messages.updateTitle)}
    >
      <Modal.Body>
        <FlexboxGrid justify='space-around' align='top'>
          <FlexboxGrid.Item colspan={11}>
            <Label>
              <FormattedMessage
                id='user.role.form.label.name'
                defaultMessage='Name'
              />
              <WarningSpan>*</WarningSpan>
            </Label>

            <Input
              name='name'
              style={{ margin: '10px 0' }}
              size='lg'
              value={data.name}
              onChange={(value) => {
                setNameErrors([]);
                setData({ ...data, name: value });
              }}
              placeholder={formatMessage(messages.placeholder)}
              maxLength={MAX_LENGTH}
            />

            {nameErrors.map((msg) => <WarningSpan key={msg}>{msg}</WarningSpan>)}
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={11} />

          <FlexboxGrid.Item colspan={23} style={{ marginTop: '10px' }}>
            <Label>
              <FormattedMessage
                id='user.role.form.label.details'
                defaultMessage='Details'
              />
            </Label>

            <Input
              name='details'
              style={{ marginTop: '10px' }}
              as='textarea'
              rows={4}
              size='lg'
              value={data.details}
              onChange={(value) => setData({ ...data, details: value })}
              placeholder={formatMessage(messages.detailsPlaceholder)}
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Body>

      <Modal.Footer>
        <FlexboxGrid justify='space-around' align='top'>
          <FlexboxGrid.Item colspan={14} />
          <FlexboxGrid.Item colspan={10}>
            <PrimaryButton data-testid='submit' onClick={submit} style={{ width: '100%' }}>
              <FormattedMessage
                id='user.role.form.submit.button'
                defaultMessage='Submit'
              />
            </PrimaryButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </OpenModal>
  );
};

export default UserRoleForm;
