import { Checkbox } from 'rsuite';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
    .rs-checkbox-wrapper .rs-checkbox-inner:before,
    label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
        border: 2px solid ${(props) => props.theme.colors.primary};
    }
    &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
    &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
        background-color: ${(props) => props.theme.colors.primary};
    }
`;
