import styled, { useTheme } from 'styled-components';

export const MarginDiv = styled.div`
  margin: 1rem;
`;

export const ViewMarginDiv = styled.div`
  margin-top: 34px;
`;

export const MarginLeft = styled.div`
  margin-left: 1rem;
  margin-right: 6.625rem;
`;

export const MarginSpan = styled.span`
  margin: 0.3rem;
`;

export const WarningSpan = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

export const HeaderSpan = styled.span`
  color: ${(props) => props.theme.colors.font_dark};
  font-size: ${(props) => props.theme.font.sizes.xs};
  font-weight: ${(props) => props.theme.font.weights.bold};
  text-transform: uppercase;
`;

export const WhiteDiv = styled.div`
  margin-top: 1.25rem;
`;

export const ActionImage = styled.img`
  margin-right: 8px;
`;

const IconWrapper = styled.span`
  font-size: ${(props) => props.theme.font.sizes.standard};
  margin: 0.3rem;
`;

export const FaIcon = ({ icon }) => (
  <IconWrapper>
    {icon === 'chevron-down'
      ? (
        <i className={`fa-solid fa-${icon}`} style={{ color: '#010005' }} />
        )
      : (
        <i className={`fa-solid fa-${icon}`} />
        )}
  </IconWrapper>
);

export const FaRegularIcon = ({ icon }) => (
  <IconWrapper>
    <i className={`fa-regular fa-${icon}`} />
  </IconWrapper>
);

const BigIconWrapper = styled.div`
  margin: 0.75rem;
  font-size: ${(props) => props.theme.font.sizes.xl};
  vertical-align: top;
`;

export const BigFaIcon = ({ icon }) => {
  const theme = useTheme();

  const style1 = { color: theme.colors.tertiary };
  const style2 = { color: theme.colors.primary };

  return (
    <BigIconWrapper className='fa-stack'>
      <i className='fa-solid fa-stack-2x fa-circle' style={style1} />
      <i className={`fa-solid fa-stack-1x fa-${icon}`} style={style2} />
    </BigIconWrapper>
  );
};
