import ReactECharts from 'echarts-for-react';
import { groupBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { StyledChartsPanel } from '../../../containers/styled/styled';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { getCurrentMonthData } from '../../../utils';
import { StyledSubHeading } from '../styled';

const DonorsByGender = ({ title, donorsChildData, onChartImageReady }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [data, setData] = useState([]);
  const chartRef = useRef(null);

  const headingStyle = {
    color: '#010005',
    marginTop: 0
  };

  const handleChartFinished = () => {
    const chart = chartRef.current.getEchartsInstance();
    const chartImage = chart.getDataURL({ pixelRatio: 2 });
    onChartImageReady(chartImage);
  };

  useEffect(() => {
    const subscription = getCollection(db, 'donor')
      .find()
      .where({ isActive: true })
      .$
      .subscribe((entries) => {
        const groupedData = groupBy(getCurrentMonthData(entries), 'gender');
        const dataPoints = Object.keys(groupedData);
        const formattedData = dataPoints.map((name) => ({
          name: name === 'M' ? 'Male' : 'Female',
          value: groupedData[name].length
        }));
        setData(formattedData);
        donorsChildData(formattedData);
      });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line
  }, [db]);

  const options = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      icon: 'circle',
      bottom: '0'
    },
    series: [
      {
        name: 'Donors',
        type: 'pie',
        radius: ['100%', '80%'],
        bottom: '40',
        avoidLabelOverlap: false,
        color: ['#FBE7E3', '#B02C17'],
        itemStyle: {
          borderRadius: 0,
          borderColor: '#fff',
          borderWidth: 5
        },
        emphasis: {
          disabled: true
        },
        labelLine: {
          show: false
        },
        label: {
          show: false,
          position: 'center',
          fontFamily: 'Manrope'
        },
        data
      }
    ]
  };

  return (
    <StyledChartsPanel>
      <StyledSubHeading style={headingStyle}>{formatMessage(title)}</StyledSubHeading>
      <ReactECharts
        option={options}
        onEvents={{ finished: handleChartFinished }}
        ref={chartRef}
      />
    </StyledChartsPanel>
  );
};
export default DonorsByGender;
