import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import Modal from 'rsuite/Modal';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { WarningSpan } from '../../../containers/styled/layout';
import { SelectPickerField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import { listFacility } from '../../../controllers/reports/CommonController';
import {
  validateLength
} from '../../../controllers/validators';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  placeholder: {
    id: 'donationSchedule.placeholder',
    defaultMessage: 'Enter Name of Category'
  },
  detailsPlaceholder: {
    id: 'donationSchedule.form.details.placeholder',
    defaultMessage: 'Select Details'
  },
  weekPlaceholder: {
    id: 'donationSchedule.form.week.placeholder',
    defaultMessage: 'Select Week'
  },
  startTimePlaceholder: {
    id: 'donationSchedule.form.startTime.placeholder',
    defaultMessage: 'Select Time'
  },
  endTimePlaceholder: {
    id: 'donationSchedule.form.endTime.placeholder',
    defaultMessage: 'Select Time'
  },

  // CREATE
  createTitle: {
    id: 'donationSchedule.create.title',
    defaultMessage: 'Create Schedule'
  },
  createHeader: {
    id: 'donationSchedule.form.create.header',
    defaultMessage: 'Created'
  },
  createSuccess: {
    id: 'donationSchedule.form.create.success',
    defaultMessage: 'DOnation Schedule Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'donationSchedule.form.update.title',
    defaultMessage: 'Update Donation Schedule'
  },
  updateHeader: {
    id: 'donationSchedule.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'donationSchedule.form.update.success',
    defaultMessage: 'Donation Schedule Updated Successfully'
  },

  failed: {
    id: 'donationSchedule.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'donationSchedule.form.save.failed',
    defaultMessage: 'Could not save Donation Schedule'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'donationSchedule.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'donationSchedule.form.name.error.exists',
    defaultMessage: 'Donation Schedule already exists'
  },
  nameMaxLengthError: {
    id: 'donationSchedule.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

const MAX_LENGTH = 40;

/**
 * Donation Schedule Form screen
 *
 * Donation Schedule form to create / update categories.
 *
 * @component
 * @param {object} item             prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose     handles modal close event
 * @returns category form modal.
 *
 */
const Form = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [data, setData] = React.useState({ name: '', details: '' });
  const [nameErrors, setNameErrors] = React.useState([]);
  const [query, setQuery] = React.useState({
    donationCenter: ''
  });
  const [facilityData, setFacilityData] = React.useState([]);

  const weekData = [
    {
      label: 'Monday'
    },
    {
      label: 'Tuesday'
    },
    {
      label: 'Wednesday'
    },
    {
      label: 'Thursday'
    },
    {
      label: 'Friday'
    },
    {
      label: 'Saturday'
    }
  ];

  const timeData = [
    {
      label: '9:00 AM'
    },
    {
      label: '10:00 AM'
    },
    {
      label: '11:00 AM'
    },
    {
      label: '12:00 AM'
    },
    {
      label: '01:00 PM'
    },
    {
      label: '02:00 PM'
    }
  ];

  React.useEffect(() => {
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => (entry.isActive && entry.requiresBlood)) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setFacilityData(docs);
    });
  }, [db]);

  const validateName = () => {
    setNameErrors([]);
    const errors = [];

    try {
      validateLength(data.name, MAX_LENGTH);
    } catch (err) {
      errors.push(
        err.message === 'blank'
          ? formatMessage(messages.requiredError)
          : formatMessage(messages.nameMaxLengthError, { limit: MAX_LENGTH })
      );
    }

    setNameErrors([errors]);
    return errors.length === 0;
  };

  const submit = (event) => {
    event.stopPropagation();

    if (!validateName()) return;

    let action;
    let header;
    let message;

    if (!data.id) {
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  React.useEffect(() => {
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      details: entry.details || ''
    });
  }, [item]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(
        !data.id ? messages.createTitle : messages.updateTitle
      )}
    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='donationSchedule.form.label.name'
                  defaultMessage='Blood Donation Center'
                />
                <WarningSpan>*</WarningSpan>
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='facility'
                  value={query.facility}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.placeholder)}
                  data={facilityData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, facility: value }))}
                />
              </SelectDiv>
              {nameErrors.map((msg) => (
                <WarningSpan key={msg}>{msg}</WarningSpan>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='donationSchedule.form.label.week'
                  defaultMessage='Day of Week'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='week'
                  value={weekData[0].label}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.weekPlaceholder)}
                  data={weekData}
                />
              </SelectDiv>
            </Col>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='donationSchedule.form.label.startTime'
                  defaultMessage='Start Time'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='startTime'
                  value={timeData[0].label}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.startTimePlaceholder)}
                  data={timeData}
                />
              </SelectDiv>
            </Col>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='donationSchedule.form.label.endTime'
                  defaultMessage='End Time'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='endTime'
                  value={timeData[0].label}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.endTimePlaceholder)}
                  data={timeData}
                />
              </SelectDiv>
            </Col>
            <Col xs={12}>
              <PrimaryButton
                data-testid='submit' onClick={submit}
                style={{ width: '100%', marginTop: '42px', paddingTop: '10px' }}
              >
                <FormattedMessage
                  id='facility.category.form.submit.button'
                  defaultMessage='Submit'
                />
              </PrimaryButton>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </OpenModal>
  );
};

export default Form;
