import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { choices } from '../../../constants/choices';
import { FilterModal } from '../../../containers/styled/alerts';
import { TertiaryButton } from '../../../containers/styled/buttons';
import {
  DateField,
  SelectPickerField
} from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import {
  listFacility
} from '../../../controllers/reports/CommonController';
import { parseDate } from '../../../utils/dates';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  facilityPlaceholder: {
    id: 'report.filter.patients.facility.placeholder',
    defaultMessage: 'Select Facility'
  },
  statusPlaceholder: {
    id: 'report.filter.patients.status.placeholder',
    defaultMessage: 'Select Status'
  },
  bloodTypePlaceholder: {
    id: 'report.filter.patients.bloodType..placeholder',
    defaultMessage: 'Select Type'
  },
  genderPlaceholder: {
    id: 'report.filter.patients.gender.placeholder',
    defaultMessage: 'Select Gender'
  },
  datePeriodPlaceholder: {
    id: 'report.filter.patients.datePeriod.placeholder',
    defaultMessage: 'Select Date'
  },
  maleShort: {
    id: 'report.filter.patients.m',
    defaultMessage: 'M'
  },
  male: {
    id: 'report.filter.patients.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'report.filter.patients.female',
    defaultMessage: 'Female'
  }
});

/**
 * Patient Filter screen
 *
 * Patient filter to display modal to filter data table.
 *
 * @component
 * @param {array} data              an array of objects representing the data to be displayed
 * @param {boolean} handleClose     handles modal close event
 * @param setFilteredData           sets filtered data to the data table
 * @param initialQuery              default data to the data table
 * @param setInitialQuery           sets initialQuery data to table before modal closes
 * @returns patient filter modal.
 *
 */
const PatientsFilter = ({ data, handleClose, setFilteredData, initialQuery, setInitialQuery }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [facilityData, setFacilityData] = React.useState([]);
  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    gender: '',
    bloodType: '',
    datePeriod: ''
  });

  React.useEffect(() => {
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setFacilityData(docs);
    });
  }, [db]);

  const filterTable = () => {
    const filteredData = data.filter(
      (entry) =>
        (!query.facility || entry.facility === query.facility) &&
        (!query.status || entry.status === query.status) &&
        (!query.bloodType || entry.bloodType === query.bloodType) &&
        (!query.gender || (entry.gender === formatMessage(messages.maleShort)
          ? formatMessage(messages.male)
          : formatMessage(messages.female)) === query.gender) &&
        (query.datePeriod.length === 0 || (
          parseDate(entry.createdAt) >= parseDate(query.datePeriod[0]) &&
          parseDate(entry.createdAt) <= parseDate(query.datePeriod[1]))
        )
    );
    setFilteredData(filteredData);
    setInitialQuery(query);
    handleClose();
  };

  React.useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <FilterModal onClose={handleClose} size='xs'>
      <Modal.Body style={{ marginLeft: 26, marginRight: 26 }}>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.patients.facility'
                  defaultMessage='Facility'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='facility'
                  value={query.facility}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilityData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, facility: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.patients.status'
                  defaultMessage='Status'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='status'
                  value={query.status}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.statusPlaceholder)}
                  data={choices.patientStatus.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.id
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, status: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.patients.bloodType'
                  defaultMessage='Blood Type'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='bloodType'
                  value={query.bloodType}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(
                    messages.bloodTypePlaceholder
                  )}
                  data={choices.bloodType.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, bloodType: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.patients.gender'
                  defaultMessage='Gender'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  name='gender'
                  value={query.gender}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.genderPlaceholder)}
                  data={choices.gender.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, gender: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.patients.datePeriod'
                  defaultMessage='Date Period'
                />
              </Label>
              <SelectDiv>
                <DateField
                  name='datePeriod'
                  value={query.datePeriod.length ? query.datePeriod : null}
                  className='select-md'
                  block
                  placeholder={formatMessage(messages.datePeriodPlaceholder)}
                  onClean={() => setQuery((state) => ({ ...state, datePeriod: [] }))}
                  onOk={(value) =>
                    setQuery((state) => ({ ...state, datePeriod: value }))}
                />
              </SelectDiv>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24}>
              <TertiaryButton data-testid='filter-button' onClick={() => filterTable()}>
                <FormattedMessage
                  id='report.filter.button'
                  defaultMessage='Filter'
                />
              </TertiaryButton>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </FilterModal>
  );
};
export default PatientsFilter;
